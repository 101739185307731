import React from "react";
import "./Toggle.css";

interface ToggleProps {
  onLabel: string;
  offLabel: string;
  onChange: (isChecked: boolean) => void;
  isChecked?: boolean;
  disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ onLabel, offLabel, onChange, isChecked = false, disabled = false }) => {
  return (
    <div className="toggle-switch">
      <span className={!isChecked ? "active" : ""}>{onLabel}</span>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />
        <span className="slider"></span>
      </label>
      <span className={isChecked ? "active" : ""}>{offLabel}</span>
    </div>
  );
};

export default Toggle;
