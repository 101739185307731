import { useState, useEffect } from "react";

const MAX_HISTORY_ITEMS = 10;
const STORAGE_KEY = "cfoSfoSearchHistory";

export interface SearchHistoryItem {
  orderId: string;
  timestamp: number;
}

export function useSearchHistory() {
  const [history, setHistory] = useState<SearchHistoryItem[]>([]);

  useEffect(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      setHistory(JSON.parse(stored));
    }
  }, []);

  const addToHistory = (searchOrderId: string) => {
    const newHistory = [
      { orderId: searchOrderId, timestamp: Date.now() },
      ...history.filter((item) => item.orderId !== searchOrderId),
    ].slice(0, MAX_HISTORY_ITEMS);

    localStorage.setItem(STORAGE_KEY, JSON.stringify(newHistory));
    setHistory(newHistory);
  };

  const clearHistory = () => {
    localStorage.removeItem(STORAGE_KEY);
    setHistory([]);
  };

  return {
    history,
    addToHistory,
    clearHistory,
  };
}
