import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {
  IBAOrderEntityDetails,
  IBAOrderIneligibilityDetails,
  MultiCompanyOrderingDataDetails,
  Purchase,
} from "../../Types/OrderInfoTypes/OrderTypes";

interface OrderTableItem {
  field: string;
  value: string | boolean | number;
}

export type IBAOrderEntityType =
  | IBAOrderEntityDetails
  | IBAOrderIneligibilityDetails
  | MultiCompanyOrderingDataDetails
  | null;

interface OrderDetailsTableProps {
  ibaOrderEntity: IBAOrderEntityType;
  purchase: Purchase;
  kairos: boolean;
  paymentMethodCode: string[] | null;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
}

export const OrderDetailsTable: React.FC<OrderDetailsTableProps> = ({
  ibaOrderEntity,
  purchase,
  kairos,
  paymentMethodCode,
  isSmallScreen,
  isMediumScreen,
}) => {
  const tableColumnDefinitions = React.useMemo(
    () => [
      {
        id: "field",
        header: "Field",
        cell: (item: OrderTableItem) => (
          <Box variant="small" fontWeight="bold" padding={{ vertical: "xs", horizontal: "s" }}>
            {item.field}
          </Box>
        ),
        minWidth: 120,
        width: isSmallScreen ? 120 : isMediumScreen ? 150 : 200,
      },
      {
        id: "value",
        header: "Value",
        cell: (item: OrderTableItem) => (
          <Box variant="small" color="text-body-secondary" padding={{ vertical: "xs", horizontal: "s" }}>
            {String(item.value)}
          </Box>
        ),
        minWidth: 150,
      },
    ],
    [isSmallScreen, isMediumScreen]
  );

  const purchaseItems: OrderTableItem[] = [
    { field: "Ship to", value: purchase.shipTo },
    { field: "Date and Time", value: purchase.dateAndTimeOfPurchase },
    { field: "Kairos", value: kairos },
    { field: "Payment Methods", value: paymentMethodCode?.join(", ") ?? "-" },
  ];

  const orderItems: OrderTableItem[] = ibaOrderEntity
    ? Object.entries(ibaOrderEntity).map(([field, value]) => ({ field, value }))
    : [];

  const tableContainerStyle = {
    backgroundColor: "#ffffff",
    marginBottom: "20px",
    border: "2px solid #eaeded",
    borderRadius: "4px",
  };

  return (
    <SpaceBetween size={isSmallScreen ? "s" : "l"}>
      <div style={tableContainerStyle}>
        <Table
          header={
            <Header variant="h2" headingTagOverride="h3">
              Purchase Details
            </Header>
          }
          columnDefinitions={tableColumnDefinitions}
          items={purchaseItems}
          variant="embedded"
          stripedRows
          wrapLines
          resizableColumns
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No data available</b>
            </Box>
          }
        />
      </div>

      <div style={tableContainerStyle}>
        <Table
          header={
            <Header variant="h2" headingTagOverride="h3">
              Order Details
            </Header>
          }
          columnDefinitions={tableColumnDefinitions}
          items={orderItems}
          variant="embedded"
          stripedRows
          wrapLines
          resizableColumns
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No data available</b>
            </Box>
          }
        />
      </div>
    </SpaceBetween>
  );
};
