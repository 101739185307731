import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import BusinessAuditPage from "Pages/BusinessAuditPage";
import BusinessEnrolmentPage from "Pages/BusinessEnrolmentPage";
import BusinessUnenrolmentPage from "Pages/BusinessUnenrolmentPage";
import CfoSfoMappingsPage from "Pages/CfoSfoMappingsPage";
import OrderDetailPage from "Pages/OrderDetailPage";
import HomePage from "Pages/HomePage/homePage";

const PAGES = {
  CFO_SFO_MAPPINGS: "cfo-sfo-mappings",
  BUSINESS_AUDIT: "business-audit",
  BUSINESS_ENROLMENT: "business-enrolment",
  BUSINESS_UNENROLMENT: "business-unenrolment",
  ORDER_INFO: "order-info",
};

interface Route {
  readonly page: string;
  readonly name: string;
  readonly element: React.ReactNode;
}

export const ROUTES: Route[] = [
  {
    page: PAGES.CFO_SFO_MAPPINGS,
    name: "CFO SFO Mappings",
    element: <CfoSfoMappingsPage />,
  },
  {
    page: PAGES.BUSINESS_AUDIT,
    name: "Business Audit",
    element: <BusinessAuditPage />,
  },
  {
    page: PAGES.BUSINESS_ENROLMENT,
    name: "Enrol Businesses",
    element: <BusinessEnrolmentPage />,
  },
  {
    page: PAGES.BUSINESS_UNENROLMENT,
    name: "Unenrol Businesses",
    element: <BusinessUnenrolmentPage />,
  },
  {
    page: PAGES.ORDER_INFO,
    name: "Order Info",
    element: <OrderDetailPage />,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {ROUTES.map(({ page, element }) => (
        <Route key={page} path={page} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
