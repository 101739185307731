import Link from "@amzn/awsui-components-react/polaris/link";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { SearchHistoryItem } from "../../hooks/useSearchHistory";

interface SearchHistoryProps {
  history: SearchHistoryItem[];
  onHistoryItemClick: (orderId: string) => void;
  onClearHistory: () => void;
}

const styles = {
  historyContainer: {
    padding: "8px 16px",
    backgroundColor: "#f8f8f8",
    borderRadius: "4px",
    border: "1px solid #eaeded",
  },
  label: {
    fontSize: "14px",
    color: "#545b64",
    fontWeight: "bold",
  },
  historyItem: {
    display: "inline-block",
    padding: "4px 8px",
    margin: "0 4px",
    backgroundColor: "#fff",
    border: "1px solid #d5dbdb",
    borderRadius: "3px",
    fontSize: "13px",
  },
  clearButton: {
    marginLeft: "auto",
  },
};

export const SearchHistory = ({ history, onHistoryItemClick, onClearHistory }: SearchHistoryProps) => {
  if (history.length === 0) {
    return null;
  }

  return (
    <Box margin={{ top: "s" }}>
      <div style={styles.historyContainer}>
        <SpaceBetween direction="vertical" size="xs">
          <div style={styles.label}>Recent searches</div>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {history.map((item) => (
              <div key={item.orderId} style={styles.historyItem}>
                <Link onFollow={() => onHistoryItemClick(item.orderId)} href="#">
                  {item.orderId}
                </Link>
              </div>
            ))}
            <Button variant="link" onClick={onClearHistory}>
              Clear history
            </Button>
          </div>
        </SpaceBetween>
      </div>
    </Box>
  );
};
