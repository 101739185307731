import React from "react";
import { Grid } from "@amzn/awsui-components-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import { Order, OrderDetails } from "../../Types/OrderInfoTypes/OrderTypes";
import { IBAOrderEntityType, OrderDetailsTable } from "../../components/OrderInfoComponents/IBAOrderDetailsTable";

interface OrderDetailResponseItem {
  id: React.ReactNode;
  refOrder: string;
  auditStatus: string;
  links: string;
}

const OrderDetailPageResult: React.FC<{ response: OrderDetails }> = ({ response }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [orderEntity, setOrderEntity] = React.useState<IBAOrderEntityType>(null);
  const [kairosIsActivated, setKairosIsActivated] = React.useState(false);
  const [paymentMethodCode, setPaymentMethodCode] = React.useState<string[] | null>(null);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!response.purchase) {
    return (
      <Alert type="error" header="Invalid Input">
        {response.inputId} is not a valid input.
      </Alert>
    );
  }

  const isSmallScreen = windowWidth < 768;
  const isMediumScreen = windowWidth >= 768 && windowWidth < 1024;
  const { purchase } = response;

  const updateOrderEntity = (order: Order) => {
    if (!orderEntity) {
      setOrderEntity(
        order.orderType === "CFO"
          ? order.ibaOrderEntityDetails
          : order.orderType === "SFO"
          ? order.multiCompanyOrderingDataDetails
          : order.ibaOrderIneligibilityDetails
      );
      setKairosIsActivated(order.kairosIsActivated);
      setPaymentMethodCode(order.paymentMethodCodeList);
    }
  };

  const createBoxWithId = (id: string) => <Box padding={{ vertical: "xs" }}>{id}</Box>;

  const purchaseItems = [{ id: createBoxWithId(purchase.purchaseId), refOrder: "", auditStatus: "", links: "" }];

  const orderItems = purchase.ordersList.map((order) => {
    updateOrderEntity(order);
    return {
      id: (
        <Box padding={{ vertical: "xs" }}>
          <SpaceBetween size="xs" direction="horizontal">
            <span>{order.orderId}</span>
            <span>{order.orderType}</span>
          </SpaceBetween>
        </Box>
      ),
      refOrder: order.refOrderIdList?.join(", ") ?? "",
      auditStatus: order.workflowAuditState,
      links: "",
    };
  });

  const shipmentItems = purchase.ordersList.flatMap((order) =>
    order.shipmentsList.map((shipment) => ({
      id: createBoxWithId(shipment.shipmentId),
      refOrder: order.orderId,
      auditStatus: shipment.workflowAuditStatus,
      links: "",
    }))
  );

  const reversalItems = purchase.ordersList.flatMap((order) =>
    order.reversalsList.map((reversal) => ({
      id: createBoxWithId(reversal.reversalId),
      refOrder: order.orderId,
      auditStatus: reversal.workflowAuditStatus,
      links: "",
    }))
  );

  const columnDefinitions = React.useMemo(() => {
    const baseColumns = [
      {
        id: "ids",
        header: "ID",
        cell: (item: OrderDetailResponseItem) => item.id,
        minWidth: 150,
        width: isSmallScreen ? 150 : 200,
      },
      {
        id: "refOrder",
        header: "Corresponding order",
        cell: (item: OrderDetailResponseItem) => (
          <Box color="text-body-secondary" padding={{ vertical: "xs" }} textAlign="left">
            <div style={{ whiteSpace: "nowrap" }}>{item.refOrder || "-"}</div>
          </Box>
        ),
        minWidth: 130,
        width: isSmallScreen ? 130 : 180,
      },
    ];

    if (!isSmallScreen) {
      baseColumns.push({
        id: "auditStatus",
        header: "Monitoring status",
        cell: (item: OrderDetailResponseItem) => (
          <Box
            color={item.auditStatus?.includes("Error") ? "text-status-error" : "text-status-success"}
            fontWeight="bold"
            padding={{ vertical: "xs" }}
          >
            {item.auditStatus || "-"}
          </Box>
        ),
        minWidth: 100,
        width: isMediumScreen ? 150 : 180,
      });
    }

    if (!isSmallScreen && !isMediumScreen) {
      baseColumns.push({
        id: "links",
        header: "Links",
        cell: (item: OrderDetailResponseItem) => <Box padding={{ vertical: "xs" }}>{item.links || "-"}</Box>,
        minWidth: 120,
        width: 150,
      });
    }

    return baseColumns;
  }, [isSmallScreen, isMediumScreen]);

  const tableContainerStyle = {
    backgroundColor: "#ffffff",
    marginBottom: "20px",
    border: "2px solid #eaeded",
    borderRadius: "4px",
  };

  const renderTable = (title: string, items: OrderDetailResponseItem[]) => (
    <div style={tableContainerStyle}>
      <Table
        header={
          <Header variant="h2" headingTagOverride="h3">
            {title}
          </Header>
        }
        columnDefinitions={columnDefinitions}
        items={items}
        variant="embedded"
        wrapLines
        stripedRows
        resizableColumns
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No data available</b>
          </Box>
        }
      />
    </div>
  );

  return (
    <Grid
      gridDefinition={[
        { colspan: isSmallScreen ? 12 : isMediumScreen ? 8 : 7 },
        { colspan: isSmallScreen ? 12 : isMediumScreen ? 4 : 5 },
      ]}
    >
      <div style={{ overflowX: "auto" }}>
        {renderTable("Purchase", purchaseItems)}
        {renderTable("Orders", orderItems)}
        {renderTable("Shipments", shipmentItems)}
        {renderTable("Reversals", reversalItems)}
      </div>

      <Box variant="awsui-key-label" padding={isSmallScreen ? { top: "l" } : { left: "l" }}>
        <div style={{ overflowX: "auto" }}>
          <OrderDetailsTable
            ibaOrderEntity={orderEntity}
            purchase={purchase}
            kairos={kairosIsActivated}
            paymentMethodCode={paymentMethodCode}
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
          />
        </div>
      </Box>
    </Grid>
  );
};

export default OrderDetailPageResult;
