import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../AppRoutes";
import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import Toggle from "../../components/Toggle/Toggle";
import "./homePage.css";
import Button from "@amzn/awsui-components-react/polaris/button";

const ENVIRONMENTS = [
  {
    name: "Prod",
    url: "https://prod.iba-console.ab.amazon.dev",
    className: "env-gradient",
  },
  {
    name: "Beta",
    url: "https://beta.iba-console.ab.amazon.dev",
    className: "env-gradient",
  },
];

const DEFAULT_ENV = {
  name: "Local Development",
  url: "localhost",
  className: "env-gradient",
};

const SECTIONS = [
  {
    title: "Order Management",
    description: "Manage and track orders across different fulfillment types",
    routes: ["order-info", "cfo-sfo-mappings"],
  },
  {
    title: "Business Operations",
    description: "Handle business enrollments and compliance",
    routes: ["business-enrolment", "business-unenrolment", "business-audit"],
  },
];

const CARD_DESCRIPTIONS: Record<string, string> = {
  "cfo-sfo-mappings":
    "View and manage Customer Fulfilled Order (CFO) to Seller Fulfilled Order (SFO) mappings for order tracking.",
  "business-audit": "Review business information Invoice By Amazon related.",
  "business-enrolment": "Enrol new businesses in IBA with automated validation and marketplace selection.",
  "business-unenrolment": "Manage business unenrolment with proper documentation and reason tracking.",
  "order-info": "Order information viewer with comprehensive data about purchase, seller, and much more.",
};

const HomePage = () => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);
  const currentEnv = ENVIRONMENTS.find((env) => window.location.href.includes(env.url)) || DEFAULT_ENV;
  const timeOfDay = new Date().getHours() < 12 ? "morning" : new Date().getHours() < 18 ? "afternoon" : "evening";

  const changeEnvironment = (toProd: boolean) => {
    const targetEnv = toProd ? ENVIRONMENTS[0] : ENVIRONMENTS[1];
    window.location.href = targetEnv.url;
  };

  return (
    <IBAConsoleLayout>
      <div className="home-page-container">
        <div className={`hero-section ${currentEnv.className}`}>
          <div className="hero-content">
            <div className="hero-left">
              <div className="hero-main">
                <h1>IBA Console</h1>
                <p>Good {timeOfDay}! Welcome to your central hub for IBA management</p>
              </div>
            </div>
            {currentEnv !== DEFAULT_ENV && (
              <div className="environment-switcher">
                <Toggle
                  onLabel="Beta"
                  offLabel="Prod"
                  onChange={changeEnvironment}
                  isChecked={currentEnv.name === "Prod"}
                />
              </div>
            )}
          </div>
        </div>

        <div className="main-content">
          {SECTIONS.map((section) => (
            <div key={section.title} className="section-container">
              <div className="section-header">
                <div className="section-title">
                  <h2>{section.title}</h2>
                  <p>{section.description}</p>
                </div>
              </div>
              <div className="cards-grid">
                {ROUTES.filter((route) => section.routes.includes(route.page)).map((route) => (
                  <div
                    key={route.page}
                    className={`feature-card ${hoveredCard === route.page ? "hovered" : ""}`}
                    onMouseEnter={() => setHoveredCard(route.page)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <h3>{route.name}</h3>
                    <p>{CARD_DESCRIPTIONS[route.page]}</p>
                    <Button variant="primary" onClick={() => navigate(`/${route.page}`)} className="feature-button">
                      Go to {route.name}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </IBAConsoleLayout>
  );
};

export default HomePage;
